import axios from 'axios';

import store from '../state/store';
import { apiKeyBackend } from '../helpers/global';

const axiosAdmin = axios.create();


axiosAdmin.interceptors.request.use(async (request) => {
    let token = store.getters['auth/user'].token;
    request.headers = {
        'Accept': 'application/json',
        'Authorization': token,
        'X-API-KEY': apiKeyBackend
    }
    return request
});

axiosAdmin.interceptors.response.use((response) => {
    return response
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        
        await store.dispatch('auth/refreshToken');

        let token = await store.getters['auth/user'].token;

        axiosAdmin.defaults.headers.common['Authorization'] = token;
        return axiosAdmin(originalRequest);
    }
    if([401, 403].indexOf(error.response.status) > "-1") return await store.dispatch('auth/logout');

    return Promise.reject(error);
});


export default axiosAdmin;
