<script>
    import Layout from '../../layouts/main'
    import PageHeader from '@/components/page-header'
    
    import { urlBackend } from '../../../helpers/global';
    import axiosAdmin from '../../../api/axiosAdmin';
    
    import { required, minLength, sameAs } from "vuelidate/lib/validators";
    export default {
        components: { Layout, PageHeader },
        data() {
            return {
                title : 'Change Password',
                items: [
                    {
                        text: "Change Password",
                        href: "/ss/admin/password",
                        active: true,
                    },
                ],
                submitted : false,
                oldPassword : "",
                newPassword : "",
                confirmPassword : "",
                loadingForm : false,
            }
        },
        validations : {
            oldPassword : {
                required
            },
            newPassword : {
                required,
                minLength : minLength(8)
            },
            confirmPassword : {
                required,
                minLength : minLength(8),
                sameAsPassword : sameAs('newPassword')
            }
        },

        methods : {
            async changePassword(){
                try {
                    this.loadingForm = true;
                    this.$v.$touch();
                    this.submitted = true;
                    if (this.$v.$invalid) return false;
                    
                    const confirmAction = await this.$swal({
                        title: 'Your actions will make your password changed.<br/><br/>Are you sure?',
                        showDenyButton: true,
                        confirmButtonText: 'Yes',
                        denyButtonText: `No`,
                        allowOutsideClick: false
                    })
                    if(confirmAction.isDenied == true) return false

                    let payload = {
                        old_password : this.oldPassword,
                        new_password : this.newPassword,
                        confirm_password : this.confirmPassword,
                    }
                    let response = await axiosAdmin.post(urlBackend + '/api/v1/user/password', payload);
                    
                    this.$swal({
                        icon : 'success',
                        title : response?.data?.title ?? 'Success',
                        text : response?.data?.message ?? 'Success Change Password'
                    });
                    this.resetForm();
                    
                } catch (err) {
                    this.$swal({
                        icon : 'error',
                        title : 'Sorry!',
                        text : err?.response?.data?.message ?? err?.message ?? 'Something Wrong'
                    });
                } finally {
                    this.loadingForm = false;
                }
            },

            resetForm(){
                this.oldPassword = '';
                this.newPassword = '';
                this.confirmPassword = '';

                this.submitted = false;
            }
        },
        mounted(){
        }
    }
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-8 col-12">
                <div class="card">
                    <b-form @submit.prevent="changePassword()">
                    
                    <div class="card-body">
                        <h4 class="card-title mb-4">Change Password</h4>
                        <b-form-group
                            class="mb-3"
                            label="Old Password"
                            label-for="horizontal-firstname-input"
                            label-cols="12"
                            label-cols-sm="4"
                            content-cols-sm="8"
                            content-cols="12"
                        >
                            <b-form-input
                                id="horizontal-firstname-input"
                                type="password"
                                v-model="oldPassword"
                                placeholder="Enter Old Password"
                                :class="{ 'is-invalid': submitted && $v.oldPassword.$error }"
                            ></b-form-input>
                            <div
                                v-if="submitted && $v.oldPassword.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.oldPassword.required">Old password is required</span>
                            </div>
                        </b-form-group>

                        <b-form-group
                            class="mb-3"
                            label="New Password"
                            label-for="horizontal-firstname-input"
                            label-cols="12"
                            label-cols-sm="4"
                            content-cols-sm="8"
                            content-cols="12"
                        >
                            <b-form-input
                                id="horizontal-firstname-input"
                                type="password"
                                v-model="newPassword"
                                placeholder="Enter New Password"
                                :class="{ 'is-invalid': submitted && $v.newPassword.$error }"
                            ></b-form-input>
                            <div
                                v-if="submitted && $v.newPassword.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.newPassword.required">New password is required</span>
                                <span v-else-if="!$v.newPassword.minLength">New Password must have at least {{ $v.newPassword.$params.minLength.min }} letters.</span>
                            </div>
                        </b-form-group>

                        <b-form-group
                            class="mb-3"
                            label="Confirm Password"
                            label-for="horizontal-firstname-input"
                            label-cols="12"
                            label-cols-sm="4"
                            content-cols-sm="8"
                            content-cols="12"
                        >
                            <b-form-input
                                id="horizontal-firstname-input"
                                type="password"
                                v-model="confirmPassword"
                                placeholder="Enter Confirm Password"
                                :class="{ 'is-invalid': submitted && $v.confirmPassword.$error }"
                            ></b-form-input>
                            <div
                                v-if="submitted && $v.confirmPassword.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.confirmPassword.required">Confirm password is required</span>
                                <span v-else-if="!$v.confirmPassword.minLength">Confirm Password must have at least {{ $v.confirmPassword.$params.minLength.min }} letters.</span>
                                <span v-else-if="!$v.confirmPassword.sameAsPassword">Confirm password must be identical to New Password.</span>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-6 col-0"></div>
                            <b-button type="submit" variant="primary" class="col-sm-6 col-12" 
                                :disabled="loadingForm"
                                >
                                <b-spinner small type="grow" v-if="loadingForm"></b-spinner>
                                <i class="bx bxs-key font-size-16 align-middle me-2" v-else></i>
                                Change Password
                            </b-button>
                        </div>
                    </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
